import * as types from "../../constants";

const initialState = {
  user: null,
  token: "",
  lang: "en",
  isLogin: false,
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.AUTH_SIGN_IN_SUCCESS:
      return {
        ...state,
        user: actions.user,
        token: actions.token,
        isLogin: true,
      };

    case types.AUTH_SIGN_OUT:
      return {
        ...state,
        token: null,
        user: null,
        isLogin: false,
        lang: "en",
      };
    default:
      return state;
  }
}
