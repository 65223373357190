import React from "react";

import async from "../components/Async";

import { Sliders, Users } from "react-feather";

// Guards
const AuthGuard = async(() => import("../components/AuthGuard"));

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

// Dashboards components
const Default = async(() => import("../pages/dashboards/Default"));
const SaaS = async(() => import("../pages/dashboards/SaaS"));

// Protected routes
const ProtectedPage = async(() => import("../pages/protected/ProtectedPage"));

const dashboardsRoutes = {
  id: "Orders",
  path: "/dashboard",
  header: "Pages",
  icon: <Sliders />,
  containsHome: true,
  component: Default,
  guard: AuthGuard,

  children: null,
};

const pagesRoutes = {
  id: "OrdersDetails",
  path: "/dashboard/order",
  containsHome: true,
  component: SaaS,
  guard: AuthGuard,

  children: null,
};

// const projectsRoutes = {
//   id: "Projects",
//   path: "/projects",
//   icon: <Briefcase />,
//   badge: "8",
//   component: Projects,
//   children: null,
// };

// const invoiceRoutes = {
//   id: "Invoices",
//   path: "/invoices",
//   icon: <CreditCard />,
//   children: [
//     {
//       path: "/invoices",
//       name: "List",
//       component: InvoiceList,
//     },
//     {
//       path: "/invoices/detail",
//       name: "Details",
//       component: InvoiceDetails,
//     },
//   ],
//   component: null,
// };

// const orderRoutes = {
//   id: "Manage Request ",
//   path: "/manage_requests",
//   icon: <UserPlus />,
//   component: Orders,
//   children: null,
// };

// const shopRoutes = {
//   id: "Manage",
//   path: "/manage",
//   icon: <UserPlus />,
//   component: Shops,
//   children: null,
// };

// const tasksRoutes = {
//   id: "Manage Users",
//   path: "/tasks",
//   icon: <CheckSquare />,
//   component: Tasks,
//   children: null,
// };

// const calendarRoutes = {
//   id: "Calendar",
//   path: "/calendar",
//   icon: <CalendarIcon />,
//   component: Calendar,
//   children: null,
// };

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      // path: "/auth/sign-in",
      path: "/",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

// const componentsRoutes = {
//   id: "Components",
//   path: "/components",
//   header: "Elements",
//   icon: <Grid />,
//   children: [
//     {
//       path: "/components/alerts",
//       name: "Alerts",
//       component: Alerts,
//     },
//     {
//       path: "/components/avatars",
//       name: "Avatars",
//       component: Avatars,
//     },
//     {
//       path: "/components/badges",
//       name: "Badges",
//       component: Badges,
//     },
//     {
//       path: "/components/buttons",
//       name: "Buttons",
//       component: Buttons,
//     },
//     {
//       path: "/components/cards",
//       name: "Cards",
//       component: Cards,
//     },
//     {
//       path: "/components/chips",
//       name: "Chips",
//       component: Chips,
//     },
//     {
//       path: "/components/dialogs",
//       name: "Dialogs",
//       component: Dialogs,
//     },
//     {
//       path: "/components/expansion-panels",
//       name: "Expansion Panels",
//       component: ExpPanels,
//     },
//     {
//       path: "/components/lists",
//       name: "Lists",
//       component: Lists,
//     },
//     {
//       path: "/components/menus",
//       name: "Menus",
//       component: Menus,
//     },
//     {
//       path: "/components/pagination",
//       name: "Pagination",
//       component: Pagination,
//     },
//     {
//       path: "/components/progress",
//       name: "Progress",
//       component: Progress,
//     },
//     {
//       path: "/components/snackbars",
//       name: "Snackbars",
//       component: Snackbars,
//     },
//     {
//       path: "/components/tooltips",
//       name: "Tooltips",
//       component: Tooltips,
//     },
//   ],
//   component: null,
// };

// const formsRoutes = {
//   id: "Forms",
//   path: "/forms",
//   icon: <CheckSquare />,
//   children: [
//     {
//       path: "/forms/pickers",
//       name: "Pickers",
//       component: Pickers,
//     },
//     {
//       path: "/forms/selection-controls",
//       name: "Selection Controls",
//       component: SelectionCtrls,
//     },
//     {
//       path: "/forms/selects",
//       name: "Selects",
//       component: Selects,
//     },
//     {
//       path: "/forms/text-fields",
//       name: "Text Fields",
//       component: TextFields,
//     },
//     {
//       path: "/forms/dropzone",
//       name: "Dropzone",
//       component: Dropzone,
//     },
//     {
//       path: "/forms/editors",
//       name: "Editors",
//       component: Editors,
//     },
//     {
//       path: "/forms/formik",
//       name: "Formik",
//       component: Formik,
//     },
//   ],
//   component: null,
// };

// const tablesRoutes = {
//   id: "Tables",
//   path: "/tables",
//   icon: <List />,
//   children: [
//     {
//       path: "/tables/simple-table",
//       name: "Simple Table",
//       component: SimpleTable,
//     },
//     {
//       path: "/tables/advanced-table",
//       name: "Advanced Table",
//       component: AdvancedTable,
//     },
//     {
//       path: "/tables/data-grid",
//       name: "Data Grid",
//       component: DataGrid,
//     },
//   ],
//   component: null,
// };

// const iconsRoutes = {
//   id: "Icons",
//   path: "/icons",
//   icon: <Heart />,
//   children: [
//     {
//       path: "/icons/material-icons",
//       name: "Material Icons",
//       component: MaterialIcons,
//     },
//     {
//       path: "/icons/feather-icons",
//       name: "Feather Icons",
//       component: FeatherIcons,
//     },
//   ],
//   component: null,
// };

// const chartRoutes = {
//   id: "Charts",
//   path: "/charts",
//   icon: <PieChart />,
//   component: Chartjs,
//   children: null,
// };

// const mapsRoutes = {
//   id: "Maps",
//   path: "/maps",
//   icon: <Map />,
//   children: [
//     {
//       path: "/maps/google-maps",
//       name: "Google Maps",
//       component: GoogleMaps,
//     },
//     {
//       path: "/maps/vector-maps",
//       name: "Vector Maps",
//       component: VectorMaps,
//     },
//   ],
//   component: null,
// };

// const landingRoutes = {
//   id: "Landing Page",
//   path: "/",
//   header: "Docs",
//   icon: <Monitor />,
//   component: Landing,
//   children: null,
// };

// const documentationRoutes = {
//   id: "Documentation",
//   path: "/documentation",
//   header: "Material App",
//   icon: <BookOpen />,
//   children: [
//     {
//       path: "/documentation/welcome",
//       name: "Welcome",
//       component: Welcome,
//     },
//     {
//       path: "/documentation/getting-started",
//       name: "Getting Started",
//       component: GettingStarted,
//     },
//     {
//       path: "/documentation/environment-variables",
//       name: "Environment Variables",
//       component: EnvironmentVariables,
//     },
//     {
//       path: "/documentation/deployment",
//       name: "Deployment",
//       component: Deployment,
//     },
//     {
//       path: "/documentation/theming",
//       name: "Theming",
//       component: Theming,
//     },
//     {
//       path: "/documentation/state-management",
//       name: "State Management",
//       component: StateManagement,
//     },
//     {
//       path: "/documentation/api-calls",
//       name: "API Calls",
//       component: APICalls,
//     },
//     {
//       path: "/documentation/eslint-and-prettier",
//       name: "ESLint & Prettier",
//       component: ESLintAndPrettier,
//     },
//     {
//       path: "/documentation/support",
//       name: "Support",
//       component: Support,
//     },
//   ],
//   component: null,
// };

// const changelogRoutes = {
//   id: "Changelog",
//   path: "/changelog",
//   badge: "v2.0.0",
//   icon: <List />,
//   component: Changelog,
//   children: null,
// };

// This route is only visible while signed in
const protectedPageRoutes = {
  id: "Private",
  path: "/private",
  component: ProtectedPage,
  children: null,
  guard: AuthGuard,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardsRoutes,
  pagesRoutes,
  // pagesRoutes,
  // projectsRoutes,
  // orderRoutes,
  // shopRoutes,
  // invoiceRoutes,
  // tasksRoutes,
  // calendarRoutes,
  // componentsRoutes,
  // chartRoutes,
  // formsRoutes,
  // tablesRoutes,
  // iconsRoutes,
  // mapsRoutes,
  // documentationRoutes,
  // changelogRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes using the Presentation layout
// export const presentationLayoutRoutes = [landingRoutes];

// Routes that are protected
export const protectedRoutes = [protectedPageRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  dashboardsRoutes,
  // pagesRoutes,
  // projectsRoutes,
  // orderRoutes,
  // shopRoutes,
  // invoiceRoutes,
  // tasksRoutes,
  // calendarRoutes,
  // authRoutes,
  // componentsRoutes,
  // chartRoutes,
  // formsRoutes,
  // tablesRoutes,
  // iconsRoutes,
  // mapsRoutes,
  // documentationRoutes,
  // changelogRoutes,
];
